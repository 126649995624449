<!--
* @Description:学习中心->调查问卷
-->
<template>
  <div class="trainingWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              在线培训平台
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="nav_side">
        <nav>
          <router-link active-class="active" to="/home">首页</router-link>
          <router-link active-class="active" to="/trainingClass">安全培训</router-link>
          <router-link active-class="active" to="/trainingClass2">职业卫生</router-link>
          <router-link active-class="active" to="/studyCenter"
            >学习中心</router-link
          >
          <router-link active-class="active" to="/examCenter"
            >考试中心</router-link
          >
<!--          <router-link active-class="active" to="/newcomerPost"-->
<!--            >新人驿站</router-link-->
<!--          >-->
<!--          <router-link active-class="active" to="/liveRoom"-->
<!--            >直播大厅</router-link-->
<!--          >-->
<!--          <router-link active-class="active" to="/boutiqueCourse"-->
<!--            >精品课程</router-link-->
<!--          >-->
          <router-link active-class="active" to="/trainDynamic"
            >培训动态</router-link
          >
          <router-link active-class="active" to="/helpCenter"
            >帮助中心</router-link
          >
        </nav>
      </div>
    </header>
    <div class="main-content">
      <div class="navLocation">
        学习中心>调查问卷
      </div>
      <div class='workContentWrap'>
         <div class="titleWrap"><div class="title">课程</div></div>
        <div class="workContent">
            <!-- <div class="examList">
                <ul class='examListbox'>
                <li class='examItem' :class="{'activeExamItem':index==chooseIndex}" v-for="(item,index) in examListDate" @click='jumpExamItemPage(item,index)' :key='index'>
                    <div class="examName">{{item.examName}}</div>
                </li>
                </ul>
            </div> -->
            <div class="contentRight">
            <ul class='homeListbox'>
                <li class='homeItem' :class="{'lightItem':(index%2) === 0}" v-for="(item,index) in questionnaireList" @click='jumpExamItemPage(item,index)' :key='index'>
                    <div class="homeName">{{item.surveyTitle}}</div>
                    <span class='showIcon' :class="{'isok':item.status=='FINISHED'}" @click="goWriteQuestionnaire(item)">{{item.status=='FINISHED' ? '已完成' : '去填写'}}</span>
                </li>
            </ul>
            </div>
        </div>
      </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
export default {
  name: '',
  components: {
    footerCom
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      recordsData: [],
      activeIndex1: 0,
      activeIndex2: 0,
      chooseIndex: 0,
      pageNo: 1,
      pageSize: 999,
      questionnaireList: [],
      homeListDate: [
      ],
      examListDate: [
      ]
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    jumpExamItemPage (item, index) {
      console.log(item.examId)
      this.chooseIndex = index
    },
    getQueryStudentSurveyPage () {
      this.$axios.post(this.$apiUrl.queryStudentSurveyPage, {pageNo: this.pageNo, pageSize: this.pageSize}).then(response => {
        let res = response.data
        if (res.success) {
          this.questionnaireList = JSON.parse(JSON.stringify(res.data.records))
        }
        console.log(res)
        // if(res.success){
        //   this.questionnaireList = JSON.parse(JSON.stringify(res.data.records))
        // }
      })
    },
    goWriteQuestionnaire (item) {
      let {id, surveyInfoId, surveyTitle} = item
      this.$router.push(`/writeQuestionnaire?id=${id}&surveyInfoId=${surveyInfoId}&surveyTitle=${surveyTitle}`)
    }
  },
  mounted () {
    this.getUserInfo()
    this.getQueryStudentSurveyPage()
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 56px;
    /* padding: 0 100px; */

    .header-left-content {
      font-size: 22px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 42px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 34px;
        line-height: 34px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ::v-deep .el-dropdown {
        font-size: 18px;
        color: #4774df;
        background-color: #fff;
        height: 34px;
        line-height: 34px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .nav_side {
    width: 1240px;
    margin: 0 auto;
    height: 61px;
    line-height: 61px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 61px;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      border-bottom: 3px solid #ffffff;
    }
  }
}
  .main-content {
    width: 1240px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding: 30px;
    padding-bottom: 188px;
    background-color: transparent;
    .navLocation{
      margin-bottom: 30px;
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
    .workContentWrap{
        padding: 0px 32px;
        background: #FFFFFF;
        box-shadow: 0px 6px 30px 1px rgba(0,0,0,0.12);
        border-radius: 7px 7px 7px 7px;
        opacity: 1;
            .titleWrap{
                height: 59px;
                border-bottom: 1px solid rgba(112,112,112,0.2);
                .title{
                height: 58px;
                font-size: 22px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #1C4ECC;
                line-height: 58px;
                }
            }
    }
    .workContent{
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    .examList{
      width: 291px;
      background: #FFFFFF;
      opacity: 1;
      .examListbox{
        .examItem{
          display: flex;
          width: 100%;
          border-radius: 0px 0px 0px 0px;
          /* opacity: 0.26; */
          justify-content: space-between;
          align-items: center;
          color: #000000;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          padding: 20px 10px;
          cursor: pointer;
          .examName{
            opacity: 1;
          }
        }
        .activeExamItem{
          background: #E8F0FE;
          .examName{
          }
        }
      }
    }
    .contentRight{
      /* width: 819px; */
      flex: 1;
      min-height: 568px;
      background: #FFFFFF;
      opacity: 1;
      .title{
        color: #000000;
      }
      .topTip{
        height: 48px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 48px;
        padding-left: 30px;
      }
      .homeItem{
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .showIcon{
          font-size: 18px;
          color: #1C4ECC;
          cursor: pointer;
        }
        .isok{
            color: #FF6F00
          }
      }
      .lightItem{
        background-color: #F3F7FF
      }
    }
    }
  }
</style>
